import React, { useState, useEffect, useRef } from "react";
import "./modal.css";
import { Userform } from "../../components";
import { DefaultUserform } from "../../data/DataDefault";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Icon from "../../helpers/Icon"; // Import the custom Icon component

function Modal({ modals, visibleModals: externalVisibleModals, setVisibleModals: setExternalVisibleModals }) {
  const userform = DefaultUserform;
  const modalRefs = useRef({});
  const [internalVisibleModals, setInternalVisibleModals] = useState({});
  const visibleModals = externalVisibleModals || internalVisibleModals;
  const setVisibleModals = setExternalVisibleModals || setInternalVisibleModals;

  const handleClick = (id) => {
    setVisibleModals((prevState) => ({
      ...prevState,
      [id]: false,
    }));
    localStorage.setItem(`modal_status_${id}`, "true");
  };

  const handleOutsideClick = (e) => {
    Object.keys(modalRefs.current).forEach((id) => {
      if (modalRefs.current[id] && !modalRefs.current[id].contains(e.target)) {
        setVisibleModals((prevState) => {
          return {
            ...prevState,
            [id]: false,
          };
        });
        localStorage.setItem(`modal_status_${id}`, "true");
      }
    });
  };

  useEffect(() => {
    if (!externalVisibleModals) {
      const initializeModals = () => {
        const initialVisibility = {};
        modals.forEach((modal) => {
          const storedStatus = localStorage.getItem(`modal_status_${modal.id}`);
          initialVisibility[modal.id] = false;
          if (storedStatus !== "true" && modal.delay !== null) {
            setTimeout(() => {
              setVisibleModals((prevState) => ({
                ...prevState,
                [modal.id]: true,
              }));
              localStorage.setItem(`modal_status_${modal.id}`, "false");
            }, modal.delay || 0);
          }
        });
        setVisibleModals((prevState) => ({
          ...prevState,
          ...initialVisibility,
        }));
      };

      initializeModals();
    }

    const handleStorageChange = () => {
      const updatedVisibility = {};
      modals.forEach((modal) => {
        const storedStatus = localStorage.getItem(`modal_status_${modal.id}`);
        updatedVisibility[modal.id] = storedStatus === "false";
      });
      setVisibleModals((prevState) => ({
        ...prevState,
        ...updatedVisibility,
      }));
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [modals, externalVisibleModals, setVisibleModals]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  },);

  return (
    <AnimatePresence>
      {modals.map((modal) => (
        visibleModals[modal.id] && (
          <motion.div
            key={modal.id}
            initial={{ opacity: 0, y: -150, scale: 1 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{
              opacity: 0.5,
              y: -150,
              scale: 1,
            }}
            transition={{ duration: 0.25 }}
            style={{ display: "flex" }}
            id="modal"
            className={modal.className}
          >
            <div 
              className={modal.containerClassName}
              onClick={(e) => e.stopPropagation()}
              ref={(el) => (modalRefs.current[modal.id] = el)}
            >
              {modal.iconFa && modal.iconName && (
                <div>
                  <Icon iconFa={modal.iconFa} iconName={modal.iconName} />
                </div>
              )}
              {modal.title && <h3>{modal.title}</h3>}
              {modal.text && <p>{modal.text}</p>}
              {modal.textTwo && <p>{modal.textTwo}</p>}
              {modal.ctaLink && (
                <Link
                  id="modal-link"
                  className="btn-outline"
                  to={{ pathname: modal.ctaLink }}
                  onClick={() => setVisibleModals((prevState) => ({
                    ...prevState,
                    [modal.id]: true,
                  }))}
                >
                  <span>
                    {modal.ctaIconFa && modal.ctaIconName && (
                      <Icon iconFa={modal.ctaIconFa} iconName={modal.ctaIconName} />
                    )}
                  </span>
                  <span>{modal.ctaText}</span>
                </Link>
              )}
              {modal.button && (
                <Userform
                  darkmodeActive={true}
                  editable={false}
                  customizable={false}
                  userform={userform}
                  disableAnimation={true}
                />
              )}
              <Button className="btn-highlight" onClick={() => handleClick(modal.id)}>
                Schließen
              </Button>
            </div>
          </motion.div>
        )
      ))}
    </AnimatePresence>
  );
}

export default Modal;
