import React, { useRef, useEffect, useState } from "react";
import { useInView } from "framer-motion";
import { useNavigate } from "react-router-dom";
import "./highlights.css";
import Icon from "../../helpers/Icon";

function Highlights({ highlights, isBookable }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const navigate = useNavigate();
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    // Detect touch device
    if ("ontouchstart" in window || navigator.maxTouchPoints) {
      setIsTouchDevice(true);
    }

    const handleMouseMove = (e) => {
      const card = e.currentTarget;
      if (!card) return;

      const { left, top, width, height } = card.getBoundingClientRect();
      const x = e.clientX - left;
      const y = e.clientY - top;
      const centerX = width / 2;
      const centerY = height / 2;

      // **Smooth tilt effect**
      const rotateX = ((centerY - y) / centerY) * 12;
      const rotateY = ((x - centerX) / centerX) * 12;

      card.style.transition = "transform 0.15s ease-out";
      card.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(1.05)`;
    };

    const handleMouseLeave = (e) => {
      const card = e.currentTarget;
      if (!card) return;

      card.style.transition = "transform 0.3s ease-in-out";
      card.style.transform = "perspective(1000px) rotateX(0deg) rotateY(0deg) scale(1)";
    };

    const handleTouchMove = (e) => {
      if (!e.target.closest(".highlight")) return;
      const card = e.target.closest(".highlight");

      const touch = e.touches[0];
      const { left, top, width, height } = card.getBoundingClientRect();
      const x = touch.clientX - left;
      const y = touch.clientY - top;
      const centerX = width / 2;
      const centerY = height / 2;

      const rotateX = ((centerY - y) / centerY) * 10;
      const rotateY = ((x - centerX) / centerX) * 10;

      card.style.transition = "transform 0.2s ease-out";
      card.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(1.02)`;
    };

    const handleTouchEnd = (e) => {
      if (!e.target.closest(".highlight")) return;
      const card = e.target.closest(".highlight");

      card.style.transition = "transform 0.3s ease-in-out";
      card.style.transform = "perspective(1000px) rotateX(0deg) rotateY(0deg) scale(1)";
    };

    const cards = document.querySelectorAll(".highlight");

    if (!isTouchDevice) {
      cards.forEach((card) => {
        card.addEventListener("mousemove", handleMouseMove);
        card.addEventListener("mouseleave", handleMouseLeave);
      });
    } else {
      cards.forEach((card) => {
        card.addEventListener("touchmove", handleTouchMove);
        card.addEventListener("touchend", handleTouchEnd);
      });
    }

    return () => {
      cards.forEach((card) => {
        card.removeEventListener("mousemove", handleMouseMove);
        card.removeEventListener("mouseleave", handleMouseLeave);
        card.removeEventListener("touchmove", handleTouchMove);
        card.removeEventListener("touchend", handleTouchEnd);
      });
    };
  }, [isTouchDevice]);

  const handleHighlightClick = (serviceId) => {
    if (isBookable && serviceId) {
      navigate(`/termin?serviceId=${serviceId}`);
    }
  };

  return (
    <div ref={ref} className="highlights">
      {highlights.map((highlight) => (
        <div
          key={highlight.id}
          className={`highlight ${highlight.className} ${
            highlight.highlight ? "highlight-bookable" : ""
          }`}
          style={{
            transform: isInView ? "none" : "translateY(100px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
          onClick={() => handleHighlightClick(highlight.serviceId)}
        >
          <div className={highlight.containerClassName}>
            {highlight.iconName && (
              <Icon iconFa={highlight.iconFa} iconName={highlight.iconName} />
            )}
            {highlight.title && <h4>{highlight.title}</h4>}
            {highlight.description && <p>{highlight.description}</p>}
            {highlight.highlight && (
              <p className="highlight-flag">{highlight.highlight}</p>
            )}
            {highlight.price && <p className="highlight-price">{highlight.price}</p>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Highlights;
