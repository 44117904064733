import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { auth } from "../../firebase/firebase"; 
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import "./login.css"; // ✅ Ensure styles are applied

const LoginComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // ✅ Get returnUrl from query params or default to "/"
  const returnUrl = new URLSearchParams(location.search).get("returnUrl") || "/";

  // ✅ Track Authentication State & Redirect After Login
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate(returnUrl);
      }
    });
    return () => unsubscribe();
  }, [navigate, returnUrl]);

  // ✅ Handle Input Changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // ✅ Login Function
  const handleLogin = async () => {
    setLoading(true);
    setError("");

    try {
      await signInWithEmailAndPassword(auth, formData.email, formData.password);
      navigate(returnUrl); // ✅ Redirect after login
    } catch (err) {
      setError(`❌ Fehler: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      className="login-container"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
    >
      <h2 className="login-title">Anmelden</h2>

      {error && <motion.p className="error-text">{error}</motion.p>}

      <motion.div>
        {/* ✅ Email Input */}
        <motion.input
          className="login-input"
          type="email"
          name="email"
          placeholder="E-Mail-Adresse"
          value={formData.email}
          onChange={handleChange}
        />
        
        {/* ✅ Password Input */}
        <motion.input
          className="login-input"
          type="password"
          name="password"
          placeholder="Passwort"
          value={formData.password}
          onChange={handleChange}
        />
      </motion.div>

      {/* ✅ Login Button */}
      <motion.button
        className="btn btn-highlight"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={handleLogin}
        disabled={loading}
      >
        {loading ? "Wird verarbeitet..." : "Anmelden"}
      </motion.button>
    </motion.div>
  );
};

export default LoginComponent;
