import { useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Icon from "../../helpers/Icon";

const AccordionItem = ({ faq, accordion, toggle, open, fromJson }) => {
  const contentEl = useRef();

  const variants = {
    open: { height: "auto", opacity: 1 },
    closed: { height: 0, opacity: 0 },
  };

  const tapAnimation = { scale: 0.95 };

  if (fromJson)
    return (
      <div className={`accordion-item ${open ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => toggle(faq.title)}>
          <p className="accordion-title">{faq.title}</p>
          <AnimatePresence initial={false} mode="wait">
            {open ? (
              <motion.div
                key="minus"
                initial={{ rotate: 45 }}
                animate={{ rotate: 0 }}
                exit={{ rotate: 45 }}
                transition={{ duration: 0.1 }}
              >
                <Icon iconFa="fas" iconName="fa-minus" />
              </motion.div>
            ) : (
              <motion.div
                key="plus"
                initial={{ rotate: 45 }}
                animate={{ rotate: 0 }}
                exit={{ rotate: 45 }}
                transition={{ duration: 0.1 }}
              >
                <Icon iconFa="fas" iconName="fa-plus" />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <motion.div
          initial="closed"
          animate={open ? "open" : "closed"}
          variants={variants}
          ref={contentEl}
          className="accordion-content-container"
        >
          <div className="accordion-content-element">
            {/* ✅ Use <p> only if content is a string */}
            {typeof faq.content === "string" ? (
              <p dangerouslySetInnerHTML={{ __html: faq.content }}></p>
            ) : (
              <div className="editable-content">{faq.content}</div>
            )}
          </div>
        </motion.div>
      </div>
    );

  return (
    <motion.div
      whileTap={tapAnimation}
      className={`accordion-item ${open ? "active" : ""}`}
    >
      <div className="accordion-title" onClick={() => toggle(accordion.title)}>
        <p className="accordion-title">{accordion.title}</p>
        <AnimatePresence initial={false} mode="wait">
          {open ? (
            <motion.div
              key="minus"
              initial={{ rotate: 45 }}
              animate={{ rotate: 0 }}
              exit={{ rotate: 45 }}
              transition={{ duration: 0.1 }}
            >
              <Icon iconFa="fas" iconName="fa-minus" />
            </motion.div>
          ) : (
            <motion.div
              key="plus"
              initial={{ rotate: 45 }}
              animate={{ rotate: 0 }}
              exit={{ rotate: 45 }}
              transition={{ duration: 0.1 }}
            >
              <Icon iconFa="fas" iconName="fa-plus" />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <motion.div
        initial="closed"
        animate={open ? "open" : "closed"}
        variants={variants}
        ref={contentEl}
        className="accordion-content-container"
      >
        <div className="accordion-content-element">
          {/* ✅ Use <p> for plain text, otherwise render directly */}
          {typeof accordion.content === "string" ? (
            <p dangerouslySetInnerHTML={{ __html: accordion.content }}></p>
          ) : (
            <p>{accordion.content}</p>
          )}
        </div>
      </motion.div>
    </motion.div>
  );
};

export default AccordionItem;
