import React, { useEffect, useState } from "react";
import "./cursor.css";

const CustomCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    // Detect if it's a touch device
    const checkIfTouchDevice = () => {
      if ("ontouchstart" in window || navigator.maxTouchPoints) {
        setIsTouchDevice(true);
      }
    };

    checkIfTouchDevice();
    window.addEventListener("resize", checkIfTouchDevice);

    return () => window.removeEventListener("resize", checkIfTouchDevice);
  }, []);

  useEffect(() => {
    if (isTouchDevice) return;

    const moveCursor = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    document.addEventListener("mousemove", moveCursor);
    return () => document.removeEventListener("mousemove", moveCursor);
  }, [isTouchDevice]);

  if (isTouchDevice) return null; // Don't render cursor on touch devices

  return (
    <div
      className="custom-cursor"
      style={{ left: `${position.x}px`, top: `${position.y}px` }}
    />
  );
};

export default CustomCursor;
