import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import Icon from "../../helpers/Icon"; // Import the custom Icon component

function NavigationItems({ navigationitems, setToggleMenu, isEditing }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [navClicked, setNavClicked] = useState(false);
  const tapAnimation = { scale: 0.95 };

  // Handle navigation click with logging
  const handleNavClick = (link, e) => {
    console.log("Handle Nav Click triggered"); // Log when the function is called
    console.log("isEditing:", isEditing); // Log current isEditing value
    if (isEditing) {
      e.preventDefault(); // Prevent navigation when in editing mode
      console.log("Navigation blocked in editing mode"); // Log that navigation is blocked
    } else {
      setNavClicked(true);
      navigate(link);
      console.log("Navigating to:", link); // Log the link we're navigating to
    }
  };

  // Log where the click is coming from
  const logClick = (e) => {
    console.log("Nav item clicked:", e.target);
  };

  useEffect(() => {
    if (navClicked) {
      setToggleMenu(false);
      setNavClicked(false);
    }
  }, [location, navClicked, setToggleMenu]);

  return (
    <>
      {navigationitems.map((navigationitem) => (
        <motion.p
          whileTap={tapAnimation}
          key={navigationitem.id}
          onClick={(e) => {
            logClick(e); // Log the click event target
            handleNavClick(navigationitem.link, e); // Call handleNavClick
          }}
        >
          {isEditing ? (
            // If editing mode, display a span
            <span
              style={{
                pointerEvents: "none", // Prevent pointer events in editing mode
                cursor: "default", // Set default cursor since it's not clickable
              }}
            >
              {navigationitem.iconFa && navigationitem.iconName && (
                <Icon iconFa={navigationitem.iconFa} iconName={navigationitem.iconName} />
              )}
              <span>{navigationitem.title}</span>
            </span>
          ) : (
            // If not editing, display the NavLink
            <NavLink
              to={navigationitem.link}
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              {navigationitem.iconFa && navigationitem.iconName && (
                <Icon iconFa={navigationitem.iconFa} iconName={navigationitem.iconName} />
              )}
              <span>{navigationitem.title}</span>
            </NavLink>
          )}
        </motion.p>
      ))}
    </>
  );
}

export default NavigationItems;
