import React, { useRef } from "react";
import { useInView } from "framer-motion";
import "./box.css";
import { NavLink } from "react-router-dom";
import Icon from "../../helpers/Icon";
import { useAuth } from "../../auth/authProvider";


function Box({ box, fromJson }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const { isEditing } = useAuth();

  if (fromJson) {
    return (
      <>
        {box.map((boxItem) => (
          <div
            key={boxItem.id}
            style={{
              transform: isInView ? "none" : "translateY(100px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
            className={[boxItem.className].join(" ")}
            ref={ref}
          >
            <div className="box-img__container">
              {boxItem.imgpath && (
                <img
                  className={boxItem.imgClassName}
                  onError={(e) => (e.target.style.display = "none")}
                  src={boxItem.imgpath}
                  alt=""
                />
              )}
            </div>
            {(boxItem.title || boxItem.description) && (
              <div className="box-info__container">
                {boxItem.title && (
                  <h4 className={boxItem.headlineClassname}>{boxItem.title}</h4>
                )}
                {boxItem.description &&
                  (typeof boxItem.description === "string" ? (
                    <p
                      className={boxItem.textClassname}
                      dangerouslySetInnerHTML={{ __html: boxItem.description }}
                    ></p>
                  ) : (
                    <p className={boxItem.textClassname}>
                      {boxItem.description}
                    </p>
                  ))}

                {boxItem.secondDescription && (
                  <p className={boxItem.textClassname}>
                    {boxItem.secondDescription}
                  </p>
                )}
                {boxItem.thirdDescription && (
                  <p className={boxItem.textClassname}>
                    {boxItem.thirdDescription}
                  </p>
                )}
                {boxItem.fourthDescription && (
                  <p className={boxItem.textClassname}>
                    {boxItem.fourthDescription}
                  </p>
                )}
                {boxItem.fifthDescription && (
                  <p className={boxItem.textClassname}>
                    {boxItem.fifthDescription}
                  </p>
                )}
                {boxItem.linkName &&
                  (isEditing ? (
                    <button className={boxItem.linkClassname}>
                      {boxItem.linkNameIconName && (
                        <span>
                          <Icon
                            iconFa={boxItem.linkNameIconFa}
                            iconName={boxItem.linkNameIconName}
                          />
                        </span>
                      )}{" "}
                      {boxItem.linkName} {/* ✅ Just displays text in edit mode */}
                    </button>
                  ) : (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={boxItem.linkTarget}
                      className={boxItem.linkClassname}
                    >
                      {boxItem.linkNameIconName && (
                        <span>
                          <Icon
                            iconFa={boxItem.linkNameIconFa}
                            iconName={boxItem.linkNameIconName}
                          />
                        </span>
                      )}{" "}
                      {boxItem.linkName}
                    </a>
                  ))}
                {boxItem.secondLinkName && (
                  <a
                    href={boxItem.secondLinkTarget}
                    className={boxItem.linkClassname}
                  >
                    {boxItem.secondLinkNameIconName && (
                      <span>
                        <Icon
                          iconFa={boxItem.secondLinkNameIconFa}
                          iconName={boxItem.secondLinkNameIconName}
                        />
                      </span>
                    )}{" "}
                    {boxItem.secondLinkName}
                  </a>
                )}
                {boxItem.thirdLinkName && (
                  <a
                    href={boxItem.thirdLinkTarget}
                    className={boxItem.linkClassname}
                  >
                    {boxItem.thirdLinkNameIconName && (
                      <span>
                        <Icon
                          iconFa={boxItem.thirdLinkNameIconFa}
                          iconName={boxItem.thirdLinkNameIconName}
                        />
                      </span>
                    )}{" "}
                    {boxItem.thirdLinkName}
                  </a>
                )}
                {boxItem.buttonClassname && (
                  <NavLink
                    to={boxItem.buttonLink}
                    type="button"
                    className={boxItem.buttonClassname}
                  >
                    {boxItem.buttonText}
                  </NavLink>
                )}
                {boxItem.boxIconName && (
                  <div className="box-icon-container text-bright">
                    <Icon
                      iconFa={boxItem.boxIconFa}
                      iconName={boxItem.boxIconName}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </>
    );
  }

  return (
    <div
      style={{
        transform: isInView ? "none" : "translateY(100px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
      }}
      className={[box.className].join(" ")}
      ref={ref}
    >
      <div className="box-img__container">
        {box.imgpath && (
          <img
            className={box.imgClassName}
            onError={(e) => (e.target.style.display = "none")}
            src={box.imgpath}
            alt=""
          />
        )}
      </div>
      <div className="box-info__container">
        {box.title && <h4 className={box.headlineClassname}>{box.title}</h4>}
        {box.description && (
          <p
            className={box.textClassname}
            dangerouslySetInnerHTML={{ __html: box.description }}
          ></p>
        )}
        {box.secondDescription && (
          <p className={box.textClassname}>{box.secondDescription}</p>
        )}
        {box.thirdDescription && (
          <p className={box.textClassname}>{box.thirdDescription}</p>
        )}
        {box.fourthDescription && (
          <p className={box.textClassname}>{box.fourthDescription}</p>
        )}
        {box.fifthDescription && (
          <p className={box.textClassname}>{box.fifthDescription}</p>
        )}
        {box.linkName && (
          <a
            target="_blank"
            rel="noreferrer"
            href={box.linkTarget} // ✅ Disable navigation
            className={box.linkClassname}
            style={{
              pointerEvents: isEditing ? "none" : "auto", // ✅ Prevent clicks
              opacity: isEditing ? 0.5 : 1, // ✅ Dim to indicate it's disabled
              cursor: isEditing ? "not-allowed" : "pointer",
            }}
          >
            {box.linkNameIconName && (
              <span>
                <Icon
                  iconFa={box.linkNameIconFa}
                  iconName={box.linkNameIconName}
                />
              </span>
            )}{" "}
            {box.linkName}
          </a>
        )}
        {box.secondLinkName && (
          <a href={box.secondLinkTarget} className={box.textClassname}>
            {box.secondLinkNameIconName && (
              <span>
                <Icon
                  iconFa={box.secondLinkNameIconFa}
                  iconName={box.secondLinkNameIconName}
                />
              </span>
            )}{" "}
            {box.secondLinkName}
          </a>
        )}
        {box.thirdLinkName && (
          <a href={box.thirdLinkTarget} className={box.textClassname}>
            {box.thirdLinkNameIconName && (
              <span>
                <Icon
                  iconFa={box.thirdLinkNameIconFa}
                  iconName={box.thirdLinkNameIconName}
                />
              </span>
            )}{" "}
            {box.thirdLinkName}
          </a>
        )}
        {box.buttonClassname && (
          <button type="button" className={box.buttonClassname}>
            {box.buttonText}
          </button>
        )}
      </div>
    </div>
  );
}

export default Box;
