import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faMinus,
  faPlus,
  faCircleHalfStroke,
  faAngleDown,
  faCircleNotch,
  faExclamationTriangle,
  faSliders,
  faXmark,
  faBars,
  faAngleLeft,
  faAngleRight,
  faCalendar,
  faTimes,
  faEllipsisH,
  faCheckCircle,
  faCircle,
  faArrowCircleRight,
  faHome,
  faArrowUp,
  faPhone,
  faEnvelope,
  faStar,
  faLaptop,
  faCode,
  faFont,
  faMagnifyingGlass,
  faAtom,
  faInfo,
  faInfoCircle,
  faImage,
  faSchool,
  faBriefcase,
  faPeopleArrows,
  faPen,
} from '@fortawesome/free-solid-svg-icons';

import { faShopify } from '@fortawesome/free-brands-svg-icons';

// Add the icons to the library
library.add(
  faMinus,
  faPlus,
  faCircleHalfStroke,
  faAngleDown,
  faCircleNotch,
  faExclamationTriangle,
  faSliders,
  faXmark,
  faBars,
  faCircle,
  faAngleLeft,
  faAngleRight,
  faCalendar,
  faTimes,
  faEllipsisH,
  faCheckCircle,
  faArrowCircleRight,
  faHome,
  faArrowUp,
  faPhone,
  faEnvelope,
  faStar,
  faLaptop,
  faShopify,
  faCode,
  faFont,
  faMagnifyingGlass,
  faAtom,
  faInfo,
  faInfoCircle,
  faImage,
  faSchool,
  faBriefcase,
  faPeopleArrows,
  faPen,
);

const Icon = ({ iconFa = 'fas', iconName = 'fa-info-circle' }) => {
  // Validate iconFa and iconName
  if (!iconFa || !iconName) {
    console.warn('Invalid iconFa or iconName provided to Icon component');
    return null;
  }

  return <FontAwesomeIcon icon={[iconFa, iconName]} />;
};

export default Icon;
