import AccordionItem from "./AccordionItem";
import { useState, useRef, useEffect } from "react";
import { useInView } from "framer-motion";
import "./accordion.css";

const Accordion = ({ accordion, fromJson }) => {
  const [openKey, setOpenKey] = useState();
  const accordionRef = useRef();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const handleOutsideClick = (e) => {
    if (accordionRef.current && !accordionRef.current.contains(e.target)) {
      handleToggle();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  const handleToggle = (key) => {
    setOpenKey(openKey !== key ? key : null);
  };

  return (
    <div
      className="accordion-container"
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateY(100px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
      }}
    >
      <div className="accordion-items-container" ref={accordionRef}>
        {fromJson
          ? accordion.map((accordionItem, index) => (
              <AccordionItem
                key={index}
                accordion={accordionItem}
                toggle={handleToggle}
                open={openKey === accordionItem.title}
              />
            ))
          : accordion && (
              <AccordionItem
                fromJson={fromJson}
                key={accordion.index}
                accordion={accordion}
                toggle={handleToggle}
                open={openKey === accordion.title}
              />
            )}
      </div>
    </div>
  );
};

export default Accordion;
