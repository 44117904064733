import React, { useState, useEffect, useRef } from "react";
import { useInView, motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import "./fullscreen.css";

const logoDark = require("../../images/jk_logo_black.png");
const logoLight = require("../../images/jk_logo_white.png");

// Your secret key for AES encryption
const secretKey = "Henrich8ktader_";

// Access code hash from environment variables
const storedHash = process.env.REACT_APP_ACCESS_CODE_HASH;

function Fullscreen(props) {
  const location = useLocation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const [logo, setLogo] = useState(logoDark);
  const isActive = props.isLoggedIn;
  const [password, setPassword] = useState("");
  const [isLoggedIn, setLogin] = useState(isActive);
  const queryParams = new URLSearchParams(window.location.search);
  const accessKey = queryParams.get("access_key");

  // Function to encrypt data using AES
  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(data, secretKey).toString();
  };

  // Function to decrypt data using AES
  const decryptData = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {
    if (accessKey === "4564006454" || isLoggedIn) {
      localStorage.setItem("isLoggedIn", encryptData("true"));
      localStorage.setItem("sessionExpiry", Date.now() + 3600000); // 1 hour
    }

    const sessionExpiry = localStorage.getItem("sessionExpiry");
    if (sessionExpiry && Date.now() > sessionExpiry) {
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("sessionExpiry");
      setLogin(false);
    } else if (localStorage.getItem("isLoggedIn")) {
      const decryptedLoginStatus = decryptData(
        localStorage.getItem("isLoggedIn")
      );
      setLogin(decryptedLoginStatus === "true");
    } else {
      setLogin(false);
    }
  }, [accessKey, isLoggedIn]);

  // Function to unlock the page
  function unlockPage() {
    // Hash the entered password using MD5
    const hash = CryptoJS.MD5(password).toString();
    // Compare the hash with the stored hash
    if (isLoggedIn || hash === storedHash) {
      setLogin(true);
      localStorage.setItem("isLoggedIn", encryptData("true"));
      localStorage.setItem("sessionExpiry", Date.now() + 3600000); // 1 hour
      window.dispatchEvent(new Event("storage"));
    } else {
      // Show error message if the code is incorrect
      document.getElementsByClassName(
        "fullscreen-password-message"
      )[0].innerHTML = "Der eingebene Code ist nicht korrekt!";
      localStorage.setItem("isLoggedIn", encryptData("false"));
      setTimeout(addAnimation, 5);
    }
  }

  // Function to add a shake animation if the code is incorrect
  function addAnimation() {
    const addanimation = document.getElementsByClassName(
      "fullscreen-unlock_button"
    );
    addanimation[0].classList.toggle("horizontal-shake");
  }

  // Handle Enter key press to trigger unlock
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      unlockPage();
    }
  };

  // Handle password input change
  function onChangePassword(e) {
    setPassword(e.target.value);
  }

  // Handle logo based on the theme
  function handleLogo() {
    if (localStorage.getItem("theme") === "dark") {
      setLogo(logoLight);
    } else setLogo(logoDark);
  }

  useEffect(() => {
    window.addEventListener("storage", handleLogo);
    handleLogo();

    return () => {
      window.removeEventListener("storage", handleLogo);
    };
  });

  // Only render Fullscreen overlay if the user is not logged in and not on the '/about' page
  const isAboutPage = location.pathname === "/about";
  if (isLoggedIn || isAboutPage) {
    return null;
  }

  return (
    <div className="fullscreen-overlay">
      <div className="fullscreen-container">
        <div className="fullscreen-content">
          <motion.img
            id="main-logo"
            className="fullscreen-overlay__logo"
            src={logo}
            alt="Logo"
            ref={ref}
            initial="initial"
            animate={isInView ? "visible" : "initial"}
            variants={{
              visible: {
                width: "100px",
                y: 0,
                opacity: 1,
                transition: {
                  duration: 0.5,
                  delay: 0.2,
                },
              },
              initial: {
                width: 0,
                y: -100,
                opacity: 0,
                transition: {
                  duration: 0.5,
                  delay: 0.2,
                },
              },
            }}
          />
          <motion.h2
            initial="initial"
            animate={isInView ? "visible" : "initial"}
            variants={{
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.5,
                  delay: 0.2,
                },
              },
              initial: {
                opacity: 0,
                y: 50,
                transition: {
                  duration: 0.5,
                },
              },
            }}
          >
            Schön, dass du hier bist.
          </motion.h2>
          <motion.p
            initial="initial"
            animate={isInView ? "visible" : "initial"}
            variants={{
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.5,
                  delay: 0.4,
                },
              },
              initial: {
                opacity: 0,
                y: 50,
                transition: {
                  duration: 0.5,
                },
              },
            }}
          >
            <strong>Hier findest du demnächst mein Portfolio.</strong>
          </motion.p>
          <motion.p
            initial="initial"
            animate={isInView ? "visible" : "initial"}
            variants={{
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.5,
                  delay: 0.6,
                },
              },
              initial: {
                opacity: 0,
                y: 50,
                transition: {
                  duration: 0.5,
                },
              },
            }}
          >
            Bis dahin kannst du mir gerne eine Mail an{" "}
            <a href="mailto:info@joshuakrieg.com">info@joshuakrieg.com</a>{" "}
            schreiben.
          </motion.p>
          <motion.p
            className="fullscreen-password-message"
            initial="initial"
            animate={isInView ? "visible" : "initial"}
            variants={{
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.5,
                  delay: 0.8,
                },
              },
              initial: {
                opacity: 0,
                y: 50,
                transition: {
                  duration: 0.5,
                },
              },
            }}
          >
            <strong></strong>
          </motion.p>
          <div className="fullscreen-unlock">
            <motion.input
              className="fullscreen-password"
              required
              type="password"
              initial="initial"
              animate={isInView ? "visible" : "initial"}
              variants={{
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.5,
                    delay: 1.0,
                  },
                },
                initial: {
                  opacity: 0,
                  y: 50,
                  transition: {
                    duration: 0.5,
                  },
                },
              }}
              placeholder="Code eingeben"
              value={password}
              onChange={onChangePassword}
              onSubmit={unlockPage}
              onKeyDown={handleKeyDown}
            />
            <motion.button
              className="fullscreen-unlock_button"
              initial="initial"
              animate={isInView ? "visible" : "initial"}
              variants={{
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.5,
                    delay: 1.2,
                  },
                },
                initial: {
                  opacity: 0,
                  y: 50,
                  transition: {
                    duration: 0.5,
                  },
                },
              }}
              onClick={unlockPage}
            >
              FREISCHALTEN
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fullscreen;
