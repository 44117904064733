import { useAuth } from "../../auth/authProvider";
import Icon from "../../helpers/Icon";
import "./editbutton.css";

export default function EditButton() {
  const { role, isEditing, setIsEditing } = useAuth();

  if (role !== "admin") return null; // ✅ Hide button for non-admins

  return (
    <button
      className="edit-page-button btn-highlight"
      onClick={() => setIsEditing(!isEditing)}
    >
      {isEditing ? <Icon iconFa="fas" iconName="xmark" /> : <Icon iconFa="fas" iconName="pen" />}
    </button>
  );
}
