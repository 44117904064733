import { useState, useEffect } from "react";
import { useAuth } from "../../auth/authProvider";
import { db } from "../../firebase/firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

export default function EditContent({ textKey, defaultText, pageKey }) {
  const { isEditing } = useAuth();
  const [text, setText] = useState(defaultText);
  const [isInputActive, setIsInputActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const uniqueKey = `${pageKey}_${textKey}`;

  useEffect(() => {
    const fetchTextFromFirestore = async () => {
      try {
        const docRef = doc(db, "editableTexts", uniqueKey);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setText(docSnap.data().value);
        } else {
          await setDoc(docRef, { value: defaultText }); 
        }
      } catch (error) {
        console.error("Error fetching text from Firestore:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTextFromFirestore();
  }, [uniqueKey, defaultText]);

  const updateTextInFirestore = async (newText) => {
    try {
      setText(newText);
      const docRef = doc(db, "editableTexts", uniqueKey);
      await updateDoc(docRef, { value: newText });
    } catch (error) {
      console.error("Error updating Firestore:", error);
    }
  };

  if (loading) {
    return null; 
  }

  if (!isEditing) {
    if (/<[^>]*>/.test(text)) {
      return (
        <>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </>
      );
    } else {
      return <>{text}</>; 
    }
  }

  return isInputActive ? (
    <textarea
      value={text}
      onChange={(e) => updateTextInFirestore(e.target.value)}
      onBlur={() => setIsInputActive(false)}
      autoFocus
      className="editable-text"
      style={{
        border: "2px solid blue",
        padding: "5px",
        fontSize: "inherit",
        fontWeight: "inherit",
        color: "inherit",
        background: "transparent",
        width: "100%",
      }}
    />
  ) : (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setIsInputActive(true);
      }}
      style={{
        cursor: "pointer",
        whiteSpace: "inherit",
        fontSize: "inherit",
        fontWeight: "inherit",
        color: "inherit",
        background: "transparent",
        border: isEditing ? "1px dotted var(--active-color)" : "none",
        pointerEvents: isEditing ? "auto" : "none",
      }}
    >
      {text}
    </div>
  );
}
