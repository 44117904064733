import React, { useState, useEffect } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import { Tooltip } from "react-tooltip";

// Default color settings
const defaultColors = {
  noData: "#D3D3D3",
  low: "#cfe2f3",
  high: "#08306b",
};

// Dummy data – these codes MUST match the GeoJSON's ISO_A2 values exactly
const defaultUserData = [
  { code: "GB", users: 125 },
  { code: "IT", users: 87 },
  { code: "ES", users: 59 },
  { code: "FR", users: 46 },
  { code: "DE", users: 45 },
  { code: "NL", users: 43 },
  { code: "US", users: 41 },
  { code: "BE", users: 36 },
  { code: "CH", users: 36 },
  { code: "AT", users: 26 },
  { code: "RO", users: 26 },
  { code: "SE", users: 22 },
  { code: "GR", users: 20 },
  { code: "PL", users: 18 },
  { code: "TR", users: 16 },
  { code: "PT", users: 13 },
  { code: "AU", users: 10 },
  { code: "KR", users: 9 },
  { code: "JP", users: 9 },
  { code: "CA", users: 6 },
  { code: "MX", users: 2 },
  { code: "BR", users: 2 },
  { code: "AR", users: 1 },
  { code: "RU", users: 3 },
  { code: "CN", users: 2 },
  { code: "IN", users: 4 },
  { code: "ZA", users: 5 },
  { code: "NG", users: 2 },
  { code: "EG", users: 5 },
  { code: "SA", users: 1 },
];

const defaultProjectionConfig = {
  projection: "geoMercator",
  scale: 140,
  center: [0,40],
};

const Legend = ({ min, max, colors }) => (
  <div style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>
    <div
      style={{
        background: `linear-gradient(to right, ${colors.low}, ${colors.high})`,
        width: "300px",
        height: "10px",
        border: "1px solid #000",
      }}
    />
    <div style={{ marginLeft: "10px" }}>
      <strong>{min} users</strong> – <strong>{max} users</strong>
    </div>
  </div>
);

// Helper function to get the full country name using a lookup
const getFullCountryName = (iso, lookup, geoData) => {
  const normalized = iso.trim().toUpperCase();
  if (lookup[normalized]) return lookup[normalized];
  if (geoData?.features) {
    const feature = geoData.features.find(
      (f) => (f.properties.ISO_A2 || "").trim().toUpperCase() === normalized
    );
    return feature ? feature.properties.ADMIN || feature.properties.NAME || "Unknown" : "Unknown";
  }
  return "Unknown";
};

const WorldMap = ({
  userData = defaultUserData,
  projectionConfig = defaultProjectionConfig,
  colors = defaultColors,
  modalEnabled = true,
}) => {
  const [data, setData] = useState([]);
  const [geoData, setGeoData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [countryNameMap, setCountryNameMap] = useState({});

  // Load user data asynchronously
  useEffect(() => {
    setTimeout(() => {
      setData(userData);
    }, 1000);
  }, [userData]);

  // Fetch the GeoJSON file from the public folder
  useEffect(() => {
    fetch("/world-countries.geojson")
      .then((res) => res.json())
      .then((jsonData) => {
        console.log("Fetched geoData:", jsonData);
        setGeoData(jsonData);
      })
      .catch((err) => console.error("Error fetching geoData:", err));
  }, []);

  // Build a lookup for full country names once geoData is available
  useEffect(() => {
    if (geoData && geoData.features) {
      const map = {};
      geoData.features.forEach((feature) => {
        const isoRaw = feature.properties?.ISO_A2 || "";
        const iso = isoRaw.trim().toUpperCase();
        if (iso && iso !== "AQ") {
          const fullName = feature.properties.ADMIN || feature.properties.NAME || "Unknown";
          map[iso] = fullName;
        }
      });
      console.log("Country lookup built:", map);
      setCountryNameMap(map);
    }
  }, [geoData]);

  const maxUsersValue = Math.max(...data.map((d) => d.users || 0), 1);
  const colorScale = scaleLinear()
    .domain([0, maxUsersValue])
    .range([colors.low, colors.high]);

  const countryData = data.reduce((acc, { code, users }) => {
    const normalized = code.trim().toUpperCase();
    acc[normalized] = users;
    return acc;
  }, {});

  const sortedData = [...data].sort((a, b) => b.users - a.users);

  return (
    <div style={{ position: "relative", width: "100%", maxWidth: "1200px", margin: "0 auto", aspectRatio: "16/9", padding: "20px" }}>
      {geoData ? (
        <ComposableMap
          width={1000}
          height={600}
          style={{ display: "block" }}
          projection={projectionConfig.projection}
          projectionConfig={{ scale: projectionConfig.scale, center: projectionConfig.center }}
        >
          <Geographies geography={geoData}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const isoRaw = geo.properties?.ISO_A2 || "";
                const iso = isoRaw.trim().toUpperCase();
                if (iso === "AQ") return null; // Skip Antarctica
                const users = countryData[iso] || 0;
                const countryName = countryNameMap[iso] || geo.properties.ADMIN || "Unknown";
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={users ? colorScale(users) : colors.noData}
                    stroke="#FFF"
                    style={{
                      default: { outline: "none" },
                      hover: { fill: "#F53", outline: "none" },
                      pressed: { fill: "#E42", outline: "none" },
                    }}
                    data-tooltip-id="tooltip"
                    data-tooltip-content={`${countryName}: ${users} users`}
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>
      ) : (
        <p>Loading GeoJSON...</p>
      )}

      <Tooltip id="tooltip" />
      {modalEnabled && (
        <button
          onClick={() => setShowModal(true)}
          style={{ fontWeight: "bold", zIndex: 10, padding: "0px", fontSize: "16px" }}
        >
          Show as List
        </button>
      )}
      <Legend min={0} max={maxUsersValue} colors={colors} />

      {modalEnabled && showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 100,
          }}
          onClick={() => setShowModal(false)}
        >
          <div
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "8px",
              maxHeight: "80vh",
              overflowY: "auto",
              width: "100%",
              maxWidth: "600px",
              position: "relative",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h3 style={{ marginTop: 0 }}>User Counts by Country</h3>
            <button
              onClick={() => setShowModal(false)}
              style={{ position: "absolute", top: "20px", right: "20px", padding: "4px 8px" }}
            >
              Close
            </button>
            <ul style={{ listStyle: "none", padding: 0 }}>
              {sortedData.map((item) => {
                const iso = item.code.trim().toUpperCase();
                const countryName = getFullCountryName(iso, countryNameMap, geoData);
                return (
                  <li key={item.code} style={{ marginBottom: "6px" }}>
                    <strong>{countryName}</strong>: {item.users} users
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorldMap;
