import React, { Suspense, lazy } from 'react';
import './index.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ScrollToTop from './hooks/ScrollToTop';
import { Footer, NavigationBar, ToTopButton, Fullscreen, Cursor, Screensaver, EditButton } from './components';
import 'animate.css';
import { AuthProvider } from './auth/authProvider';

// Lazy load the page components
const Home = lazy(() => import('./pages').then(module => ({ default: module.Home })));
const About = lazy(() => import('./pages').then(module => ({ default: module.About })));
const Contact = lazy(() => import('./pages').then(module => ({ default: module.Contact })));
const Legal = lazy(() => import('./pages').then(module => ({ default: module.Legal })));
const Customization = lazy(() => import('./pages').then(module => ({ default: module.Customization })));
const Projects = lazy(() => import('./pages').then(module => ({ default: module.Projects })));
const Services = lazy(() => import('./pages').then(module => ({ default: module.Services })));
const Test = lazy(() => import('./pages').then(module => ({ default: module.Test })));
const Login = lazy(() => import('./pages').then(module => ({ default: module.Login })));
const NotFound = lazy(() => import('./pages').then(module => ({ default: module.NotFound })));

const container = document.getElementById('root');
const root = createRoot(container);

// Detect and apply theme
const storedTheme = localStorage.getItem('theme') || "light";
document.documentElement.setAttribute("data-theme", storedTheme);
const isDarkMode = storedTheme === "dark";

// Ensure all theme colors are properly set
const defaultColors = {
  light: {
    primary: "#1e1e1e",
    secondary: "#eaeaea",
    tertiary: "#000000",
    background: "#f0f0f0",
    active: "#00a6fb",
  },
  dark: {
    primary: "#cfcfcf",
    secondary: "#1e1e1e",
    tertiary: "#f0f0f0",
    background: "#121212",
    active: "#00a6fb",
  },
};

// Get stored colors or apply defaults
const primaryColor = localStorage.getItem(isDarkMode ? 'dark_primarycolor' : 'primarycolor') || defaultColors[storedTheme].primary;
const secondaryColor = localStorage.getItem(isDarkMode ? 'dark_secondarycolor' : 'secondarycolor') || defaultColors[storedTheme].secondary;
const tertiaryColor = localStorage.getItem(isDarkMode ? 'dark_tertiarycolor' : 'tertiarycolor') || defaultColors[storedTheme].tertiary;
const backgroundColor = localStorage.getItem(isDarkMode ? 'dark_backgroundcolor' : 'backgroundcolor') || defaultColors[storedTheme].background;
const activeColor = localStorage.getItem(isDarkMode ? 'dark_activecolor' : 'activecolor') || defaultColors[storedTheme].active;

// Apply colors to CSS variables
document.documentElement.style.setProperty("--primary-color", primaryColor);
document.documentElement.style.setProperty("--secondary-color", secondaryColor);
document.documentElement.style.setProperty("--tertiary-color", tertiaryColor);
document.documentElement.style.setProperty("--background-color", backgroundColor);
document.documentElement.style.setProperty("--active-color", activeColor);

// Update theme meta color for mobile browsers
let themeColorMeta = document.querySelector("meta[name='theme-color']");
if (!themeColorMeta) {
    themeColorMeta = document.createElement("meta");
    themeColorMeta.setAttribute("name", "theme-color");
    document.head.appendChild(themeColorMeta);
}
themeColorMeta.setAttribute("content", activeColor);

// Store defaults if they were missing
if (!localStorage.getItem('primarycolor')) localStorage.setItem('primarycolor', defaultColors.light.primary);
if (!localStorage.getItem('secondarycolor')) localStorage.setItem('secondarycolor', defaultColors.light.secondary);
if (!localStorage.getItem('tertiarycolor')) localStorage.setItem('tertiarycolor', defaultColors.light.tertiary);
if (!localStorage.getItem('backgroundcolor')) localStorage.setItem('backgroundcolor', defaultColors.light.background);
if (!localStorage.getItem('activecolor')) localStorage.setItem('activecolor', defaultColors.light.active);

if (!localStorage.getItem('dark_primarycolor')) localStorage.setItem('dark_primarycolor', defaultColors.dark.primary);
if (!localStorage.getItem('dark_secondarycolor')) localStorage.setItem('dark_secondarycolor', defaultColors.dark.secondary);
if (!localStorage.getItem('dark_tertiarycolor')) localStorage.setItem('dark_tertiarycolor', defaultColors.dark.tertiary);
if (!localStorage.getItem('dark_backgroundcolor')) localStorage.setItem('dark_backgroundcolor', defaultColors.dark.background);
if (!localStorage.getItem('dark_activecolor')) localStorage.setItem('dark_activecolor', defaultColors.dark.active);


root.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <Cursor />
        <Fullscreen isLoggedIn={false} />
        <header id='header'>
          <NavigationBar />
        </header>
        <EditButton />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/services" element={<Services />} />
            <Route path="/customization" element={<Customization />} />
            <Route path="/login" element={<Login />} />
            <Route path="/test" element={<Test />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </Suspense>
        <ToTopButton />
        <Screensaver delay={120000} />
        <Footer />
      </Router>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
serviceWorkerRegistration.register();
