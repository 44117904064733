import { React, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useInView, motion } from "framer-motion";
import { useAuth } from "../../auth/authProvider"; 
import "./cta.css";

function Cta(props) {
  const { isEditing } = useAuth();
  const cta = props.cta;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const tapAnimation = { scale: 0.95 };

  return (
    <>
      {cta.map((cta) => (
        <div
          key={cta.id}
          style={{
            transform: isInView ? "none" : "translateY(100px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
          className={[cta.className].join(" ")}
          ref={ref}
        >
          <div className={cta.containerClassName}>
            {cta.headline && <h3>{cta.headline}</h3>}
            {cta.description && <p>{cta.description}</p>}

            {/* ✅ Handle Editable Button Inside NavLink */}
            {cta.buttonText && (
              <motion.div whileTap={tapAnimation}>
                {isEditing ? (
                  <span
                    className={cta.buttonClass}
                    style={{
                      border: "1px dashed red",
                      cursor: "pointer",
                      padding: "5px",
                      display: "inline-block",
                    }}
                  >
                    {cta.buttonText} {/* Editable, but no NavLink click */}
                  </span>
                ) : (
                  <NavLink to={cta.buttonLink} className={cta.buttonClass}>
                    {cta.buttonText}
                  </NavLink>
                )}
              </motion.div>
            )}

            {/* ✅ Handle External Button Links */}
            {cta.buttonTextExt && (
              <motion.div whileTap={tapAnimation}>
                {isEditing ? (
                  <span
                    className={cta.buttonClass}
                    style={{
                      border: "1px dashed blue",
                      cursor: "pointer",
                      padding: "5px",
                      display: "inline-block",
                    }}
                  >
                    {cta.buttonTextExt} {/* Editable without triggering link */}
                  </span>
                ) : (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={cta.buttonLinkExt}
                    className={cta.buttonClass}
                  >
                    {cta.buttonTextExt}
                  </a>
                )}
              </motion.div>
            )}
          </div>
        </div>
      ))}
    </>
  );
}

export default Cta;
