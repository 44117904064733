import "./userform.css";
import { useState, useEffect, useRef } from "react";
import { Saved } from "../../components";
import { useInView, motion } from "framer-motion";

function Userform({ customizable, disableAnimation }) {
  const storedTheme = localStorage.getItem("theme") || "light";
  const [theme, setTheme] = useState(storedTheme);

  // Light Mode Colors
  const [primaryColor, setPrimaryColor] = useState(
    localStorage.getItem("primarycolor") || "#1e1e1e"
  );
  const [secondaryColor, setSecondaryColor] = useState(
    localStorage.getItem("secondarycolor") || "#eaeaea"
  );
  const [tertiaryColor, setTertiaryColor] = useState(
    localStorage.getItem("tertiarycolor") || "#000000"
  );
  const [backgroundColor, setBackgroundColor] = useState(
    localStorage.getItem("backgroundcolor") || "#f0f0f0"
  );
  const [activeColor, setActiveColor] = useState(
    localStorage.getItem("activecolor") || "#00a6fb"
  );

  // Dark Mode Colors
  const [darkPrimary, setDarkPrimary] = useState(
    localStorage.getItem("dark_primarycolor") || "#ffffff"
  );
  const [darkSecondary, setDarkSecondary] = useState(
    localStorage.getItem("dark_secondarycolor") || "#222222"
  );
  const [darkTertiary, setDarkTertiary] = useState(
    localStorage.getItem("dark_tertiarycolor") || "#cccccc"
  );
  const [darkBackground, setDarkBackground] = useState(
    localStorage.getItem("dark_backgroundcolor") || "#121212"
  );
  const [darkActive, setDarkActive] = useState(
    localStorage.getItem("dark_activecolor") || "#00a6fb"
  );

  const [saveThemeDialogue, setSaveThemeDialogue] = useState(
    "Anpassungen speichern"
  );
  const [resetThemeDialogue, setResetThemeDialogue] = useState("ZURÜCKSETZEN");

  const userformContainerRef = useRef(null);
  const isInView = useInView(userformContainerRef, { once: true });
  const tapAnimation = { scale: 0.95 };

  useEffect(() => {
    const isDark = theme === "dark";
    document.documentElement.setAttribute("data-theme", theme);

    // Remove existing style tag if present
    let styleTag = document.getElementById("dynamic-theme-style");
    if (styleTag) {
      styleTag.remove();
    }

    // Create a new style tag
    styleTag = document.createElement("style");
    styleTag.id = "dynamic-theme-style";
    styleTag.innerHTML = `
        :root {
            --primary-color: ${isDark ? darkPrimary : primaryColor} !important;
            --secondary-color: ${
              isDark ? darkSecondary : secondaryColor
            } !important;
            --tertiary-color: ${
              isDark ? darkTertiary : tertiaryColor
            } !important;
            --background-color: ${
              isDark ? darkBackground : backgroundColor
            } !important;
            --active-color: ${isDark ? darkActive : activeColor} !important;
        }
    `;

    document.head.appendChild(styleTag);

    localStorage.setItem("theme", theme);
  }, [
    theme,
    primaryColor,
    secondaryColor,
    tertiaryColor,
    backgroundColor,
    activeColor,
    darkPrimary,
    darkSecondary,
    darkTertiary,
    darkBackground,
    darkActive,
  ]);

  useEffect(() => {
    const updateTheme = () => {
      const newTheme = localStorage.getItem("theme") || "light";
      setTheme(newTheme);
    };

    window.addEventListener("storage", updateTheme);
    return () => window.removeEventListener("storage", updateTheme);
  }, []);

  function updateColor(variable, value, storageKey, darkStorageKey) {
    const isDark = theme === "dark";
    document.documentElement.style.setProperty(variable, value);
    localStorage.setItem(isDark ? darkStorageKey : storageKey, value);
    window.dispatchEvent(new Event("storage"));
  }

  function handleColorChange(
    e,
    colorSetter,
    variable,
    storageKey,
    darkStorageKey
  ) {
    const newValue = e.target.value;
    colorSetter(newValue);
    updateColor(variable, newValue, storageKey, darkStorageKey);
  }

  function onChangeSave() {
    setSaveThemeDialogue(Saved);
    window.dispatchEvent(new Event("custom-colors"));
  }

  function onChangeReset() {
    if (theme === "dark") {
      setDarkPrimary("#cfcfcf");
      setDarkSecondary("#1e1e1e");
      setDarkTertiary("#f0f0f0");
      setDarkBackground("#121212");
      setDarkActive("#00a6fb");

      updateColor(
        "--primary-color",
        "#ffffff",
        "primarycolor",
        "dark_primarycolor"
      );
      updateColor(
        "--secondary-color",
        "#222222",
        "secondarycolor",
        "dark_secondarycolor"
      );
      updateColor(
        "--tertiary-color",
        "#cccccc",
        "tertiarycolor",
        "dark_tertiarycolor"
      );
      updateColor(
        "--background-color",
        "#121212",
        "backgroundcolor",
        "dark_backgroundcolor"
      );
      updateColor(
        "--active-color",
        "#00a6fb",
        "activecolor",
        "dark_activecolor"
      );
    } else {
      setPrimaryColor("#1e1e1e");
      setSecondaryColor("#eaeaea");
      setTertiaryColor("#000000");
      setBackgroundColor("#f0f0f0");
      setActiveColor("#00a6fb");

      updateColor(
        "--primary-color",
        "#1e1e1e",
        "primarycolor",
        "dark_primarycolor"
      );
      updateColor(
        "--secondary-color",
        "#eaeaea",
        "secondarycolor",
        "dark_secondarycolor"
      );
      updateColor(
        "--tertiary-color",
        "#000000",
        "tertiarycolor",
        "dark_tertiarycolor"
      );
      updateColor(
        "--background-color",
        "#f0f0f0",
        "backgroundcolor",
        "dark_backgroundcolor"
      );
      updateColor(
        "--active-color",
        "#00a6fb",
        "activecolor",
        "dark_activecolor"
      );
    }
    setResetThemeDialogue("Zurückgesetzt!");
  }

  return (
    <div
      className="userform-container"
      ref={userformContainerRef}
      style={{
        transform: !disableAnimation
          ? isInView
            ? "none"
            : "translateY(100px)"
          : null,
        opacity: !disableAnimation ? (isInView ? 1 : 0) : null,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
      }}
    >
      <form>
        {customizable && (
          <>
            <div>
              <label className="userform-label">Primärfarbe</label>
              <motion.input
                whileTap={tapAnimation}
                type="color"
                value={theme === "dark" ? darkPrimary : primaryColor}
                onChange={(e) =>
                  handleColorChange(
                    e,
                    theme === "dark" ? setDarkPrimary : setPrimaryColor,
                    "--primary-color",
                    "primarycolor",
                    "dark_primarycolor"
                  )
                }
              />
            </div>
            <div>
              <label className="userform-label">Sekundärfarbe</label>
              <motion.input
                whileTap={tapAnimation}
                type="color"
                value={theme === "dark" ? darkSecondary : secondaryColor}
                onChange={(e) =>
                  handleColorChange(
                    e,
                    theme === "dark" ? setDarkSecondary : setSecondaryColor,
                    "--secondary-color",
                    "secondarycolor",
                    "dark_secondarycolor"
                  )
                }
              />
            </div>
            <div>
              <label className="userform-label">Tertiärfarbe</label>
              <motion.input
                whileTap={tapAnimation}
                type="color"
                value={theme === "dark" ? darkTertiary : tertiaryColor}
                onChange={(e) =>
                  handleColorChange(
                    e,
                    theme === "dark" ? setDarkTertiary : setTertiaryColor,
                    "--tertiary-color",
                    "tertiarycolor",
                    "dark_tertiarycolor"
                  )
                }
              />
            </div>
            <div>
              <label className="userform-label">Hintergrundfarbe</label>
              <motion.input
                whileTap={tapAnimation}
                type="color"
                value={theme === "dark" ? darkBackground : backgroundColor}
                onChange={(e) =>
                  handleColorChange(
                    e,
                    theme === "dark" ? setDarkBackground : setBackgroundColor,
                    "--background-color",
                    "backgroundcolor",
                    "dark_backgroundcolor"
                  )
                }
              />
            </div>
            <div>
              <label className="userform-label">Akzentfarbe</label>
              <motion.input
                whileTap={tapAnimation}
                type="color"
                value={theme === "dark" ? darkActive : activeColor}
                onChange={(e) =>
                  handleColorChange(
                    e,
                    theme === "dark" ? setDarkActive : setActiveColor,
                    "--active-color",
                    "activecolor",
                    "dark_activecolor"
                  )
                }
              />
            </div>
          </>
        )}
        <div id="userform-save-btn">
          <motion.button
            whileTap={tapAnimation}
            onClick={onChangeReset}
            className="btn-outline"
            type="button"
          >
            {resetThemeDialogue}
          </motion.button>
        </div>
        <div>
          <motion.button
            whileTap={tapAnimation}
            onClick={onChangeSave}
            className="btn-highlight"
            type="button"
          >
            {saveThemeDialogue}
          </motion.button>
        </div>
      </form>
    </div>
  );
}

export default Userform;
