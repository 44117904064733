import { useState, useRef } from "react";
import { motion, useInView } from "framer-motion";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import "./contactform.css"; 

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const formRef = useRef();
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: true });
  const isOnline = useOnlineStatus();
  const tapAnimation = { scale: 0.95 };

  function handleChange(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (!isOnline) {
      setErrorMessage("⚠️ Du bist offline. Bitte versuche es später erneut.");
      return;
    }

    try {
      const response = await fetch("/.netlify/functions/sendMail", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitted(true);
      } else {
        setErrorMessage("Fehler beim Senden der Nachricht. Versuche es später erneut.");
      }
    } catch (error) {
      setErrorMessage("Netzwerkfehler. Überprüfe deine Verbindung.");
    }
  }

  return (
    <div
      ref={containerRef}
      className="contact-form-container"
      style={{
        transform: isInView ? "none" : "translateY(100px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
      }}
    >
      {submitted ? (
        <div className="contact-success">
          <h3>Nachricht gesendet!</h3>
          <p>Wir werden uns bald bei dir melden.</p>
        </div>
      ) : (
        <form ref={formRef} onSubmit={handleSubmit} className="contact-form">
          {!isOnline && <p className="offline-warning">⚠️ Du bist offline. Nachricht kann nicht gesendet werden.</p>}

          <motion.input whileTap={tapAnimation} required type="text" placeholder="Name" value={formData.name} onChange={handleChange} name="name" className="contact-input" />
          <motion.input whileTap={tapAnimation} required type="email" placeholder="E-Mail" value={formData.email} onChange={handleChange} name="email" className="contact-input" />
          <motion.textarea whileTap={tapAnimation} required placeholder="Nachricht eingeben" value={formData.message} onChange={handleChange} name="message" className="contact-textarea" />

          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <motion.button whileTap={tapAnimation} className="btn-highlight" type="submit" disabled={!isOnline}>
            Nachricht senden
          </motion.button>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
