import React, { useCallback, lazy, Suspense, useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./screensaver.css";

const Particles = lazy(() => import("react-tsparticles"));

const Screensaver = ({ delay = 10000 }) => {
  const [isScreensaverActive, setIsScreensaverActive] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const inactivityTimeout = useRef(null);
  const exitAnimationDuration = 1000;

  const particlesInit = useCallback(async (engine) => {
    const { loadFull } = await import("tsparticles");
    await loadFull(engine);
  }, []);

  useEffect(() => {
    const resetTimer = () => {
      if (inactivityTimeout.current) clearTimeout(inactivityTimeout.current);
      if (isScreensaverActive) {
        setIsScreensaverActive(false);
        setTimeout(() => setIsMounted(false), exitAnimationDuration);
      }
      inactivityTimeout.current = setTimeout(() => {
        setIsMounted(true);
        setTimeout(() => setIsScreensaverActive(true), 50);
      }, delay);
    };

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("touchstart", resetTimer);
    window.addEventListener("touchmove", resetTimer);

    inactivityTimeout.current = setTimeout(() => {
      setIsMounted(true);
      setTimeout(() => setIsScreensaverActive(true), 50);
    }, delay);

    return () => {
      if (inactivityTimeout.current) clearTimeout(inactivityTimeout.current);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("touchstart", resetTimer);
      window.removeEventListener("touchmove", resetTimer);
    };
  }, [delay, isScreensaverActive]);

  return (
    <AnimatePresence>
      {isMounted && (
        <motion.div
          className="screensaver"
          initial={{ opacity: 0, y: "100%" }}
          animate={{ opacity: 1, y: "0%" }}
          exit={{ opacity: 0, y: "100%" }}
          transition={{
            opacity: { duration: 0.5 },
            y: { type: "spring", stiffness: 80, damping: 15 },
          }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <Particles
              id="particle-container"
              init={particlesInit}
              options={{
                fullScreen: { zIndex: 1 },
                particles: {
                  number: { value: 100 },
                  size: { value: 3 },
                  move: { enable: true, speed: 2 },
                  opacity: { value: 0.5 },
                  shape: { type: "circle" },
                  color: {
                    value:
                      getComputedStyle(document.documentElement).getPropertyValue("--active-color") || "#ffffff",
                  },
                  line_linked: {
                    enable: true,
                    distance: 150,
                    color:
                      getComputedStyle(document.documentElement).getPropertyValue("--particle-line-color") || "#ffffff",
                    opacity: 0.4,
                  },
                },
              }}
            />
            <motion.div
              className="message"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.8 }}
            >
              <h1>Bist du noch da?</h1>
              <p>Bewege deine Maus oder berühre den Bildschirm um fortzufahren.</p>
            </motion.div>
          </Suspense>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Screensaver;
