import { initializeApp } from "firebase/app";
import {  initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBH8lpNFKfJUs2VJJyRNzkZfXusjgzPwvA",
    authDomain: "joshuakrieg-49e17.firebaseapp.com",
    projectId: "joshuakrieg-49e17",
    storageBucket: "joshuakrieg-49e17.firebasestorage.app",
    messagingSenderId: "137846242223",
    appId: "1:137846242223:web:76e356f798d229786c4d5a",
    measurementId: "G-MXWRFVMY09"
  };

const app = initializeApp(firebaseConfig);

// 🔥 Enable Firestore offline mode using new caching system
const db = initializeFirestore(app, {
    localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }),
});

export const auth = getAuth(app);
export { db };
