import React, { useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import "./timeline.css";
import Icon from "../../helpers/Icon";

function Timeline(props) {
  const timeline = props.timeline;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  // State to track expanded items
  const [expandedItems, setExpandedItems] = useState({});

  const toggleExpand = (id) => {
    setExpandedItems((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle expansion state
    }));
  };

  return (
    <div className="timeline-outer-wrapper">
      <div className="timeline-inner-wrapper" ref={ref}>
        {timeline.map((timeline) => (
          <motion.div
            key={timeline.id}
            initial={{ opacity: 0, y: 100 }}
            animate={{
              opacity: isInView ? 1 : 0,
              y: isInView ? 0 : 100,
            }}
            transition={{ duration: 0.9, ease: "easeInOut", delay: 0.2 }}
            className="timeline-container primary"
          >
            <div className="timeline-icon">
              <Icon iconFa={timeline.iconFa} iconName={timeline.iconName} />
            </div>
            <div className="timeline-body">
              <h2 className="timeline-title">
                <span className="badge">{timeline.title}</span>
              </h2>

              {/* ✅ Animated Expandable Description with Line Clamp */}
              <motion.div
                className={`timeline-description ${
                  expandedItems[timeline.id] ? "expanded" : "clamped"
                }`}
                initial={{ height: "80px" }} // Default to 2-line height
                animate={{
                  height: expandedItems[timeline.id] ? "auto" : "80pxm",
                }}
                transition={{
                  duration: 0.2,
                  ease: [0.17, 0.55, 0.55, 1], // ✅ Correct cubic-bezier easing
                }}
              >
                <p dangerouslySetInnerHTML={{ __html: timeline.description }} />
              </motion.div>

              {/* ✅ Expand/Collapse Button */}
              <button
                className="toggle-expand-btn"
                onClick={() => toggleExpand(timeline.id)}
              >
                {expandedItems[timeline.id] ? "Weniger anzeigen" : "Mehr anzeigen"}
              </button>

              <p className="timeline-subtitle">{timeline.subtext}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default Timeline;
